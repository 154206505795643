import { useState, React, useEffect } from "react";
import { useForm, useFieldArray, set } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useExerciseStore, useUserStore } from "../stores";
import http from "../utils/axios";
import useToast from "../utils/toast";
import { useTranslation } from "react-i18next";

import PageTitle from "../components/common/PageTitle";
import AnswerBox from "../components/vocab/AnswerBox";
import { Button, CircularProgress } from "@mui/material";
import {
  cleanStrings,
  parseAnswers,
  apiErrorMessage,
  vocabParser,
} from "../utils/helpers";
import { CopyAllOutlined, MonetizationOnOutlined } from "@mui/icons-material";
import { useLocation } from "react-router-dom";

import GenerateNewButton from "../components/exercise/GenerateNewButton";

import { VideoModal } from "../components/common/VideoModal";
import { PlayCircle } from "@mui/icons-material";

const VocabExercise = () => {
  const { t } = useTranslation(["exercise", "common"]);
  const { attemptId, redoId } = useParams();
  // attemptId = view result from history;
  // redoId = redo from history;
  // both undefined means normal flow

  const [showResult, setShowResult] = useState(() =>
    attemptId ? true : false,
  );
  const location = useLocation();
  const isRedo = location.pathname.includes("redo");

  const [isLoading, setIsLoading] = useState(false);

  const exerciseStore = useExerciseStore();
  const userStore = useUserStore();
  const navigate = useNavigate();
  const toast = useToast();

  const methods = useForm();
  const { handleSubmit, control, reset, setValue } = methods;
  const [vocabs, setVocabs] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);

  const [open, setOpen] = useState(false);

  const { fields } = useFieldArray({
    control,
    name: "qa",
  });

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const stuAnswers = data.qa.map((i) => i.answer);
      const aiPayload = {
        questions,
        answers: stuAnswers,
        words: vocabs,
        correct_answers: answers,
      };

      const aiRes = await http.post("/ai/vocab", aiPayload);
      const result = parseAnswers(aiRes.choices[0].message.content);

      let score = 0;
      const showResult = questions.map((q, i) => {
        if (result[i].correct) {
          score++;
        }
        return {
          question: q,
          answer: stuAnswers[i],
          correct_answer: answers[i],
          correct: result[i].correct,
          response: result[i]?.response,
        };
      });

      const attemptPayload = {
        qa: showResult,
        user: userStore.user._id,
        type: "vocab",
        total: questions.length,
        score,
        perc: (score / questions.length) * 100 || 0,
      };
      await http.post("/attempt", attemptPayload);
      reset(attemptPayload);
      setShowResult(true);
    } catch (error) {
      toast.error(apiErrorMessage(error) || "Unknown Error");
    } finally {
      setIsLoading(false);
    }
  };

  const getAttempt = async (id) => {
    try {
      const res = await http.get(`/attempt/${id}`);
      if (res.error === false) {
        if (attemptId) {
          const attemptPayload = {
            qa: res.item.qa,
            user: userStore.user._id,
            type: "vocab",
            total: res.item.total,
            score: res.item.score,
            perc: res.item.perc,
          };
          reset(attemptPayload);
        }
        if (redoId) {
          let questions = [];
          let vocabs = [];
          let answers = [];
          res.item.qa.forEach((i, index) => {
            questions.push(i.question);
            vocabs.push(i.correct_answer);
            answers.push(i.correct_answer);
          });

          setQuestions(questions);
          setVocabs(vocabs);
          setAnswers(answers);

          const fields = {
            qa: questions.map((str) => {
              return { question: str };
            }),
          };
          reset(fields);

          try {
            res.item.qa.forEach((i, index) => {
              if (i.correct) {
                setValue(`qa[${index}].answer`, i.answer);
              }
            });
          } catch {
            //
          }
        }
      } else {
        throw res;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const initQuestion = () => {
    setVocabs(exerciseStore.vocabOptions);
    const questions = cleanStrings(exerciseStore.vocabQuestions);
    setQuestions(questions);
    setAnswers(exerciseStore.vocabAnswers);

    const fields = {
      qa: questions.map((str) => {
        return { question: str };
      }),
    };
    reset(fields);
  };

  const generateNewExercise = async (level) => {
    try {
      setIsLoading(true);
      const q = cleanStrings(exerciseStore.vocabQuestions);
      const aiRes = await http.post("/ai/vocab", {
        words: vocabs,
        generate_level: level,
        questions: q?.length > 0 ? q : null,
      });

      const { words, questions, answers } = vocabParser(
        aiRes.choices[0].message.content,
      );

      // update store
      exerciseStore.setVocabQuestion(questions);

      setVocabs(words);
      setQuestions(questions);
      setAnswers(answers);
      const fields = {
        qa: questions.map((str) => {
          return { question: str };
        }),
      };
      reset(fields);
      setShowResult(false);
    } catch (error) {
      toast.error(apiErrorMessage(error) || "Unknown Error");
    } finally {
      setIsLoading(false);
    }
  };

  const copyToClipboard = () => {
    let text = "";
    text += vocabs.join(", ");
    text += "\n\n";
    // add question number
    text += questions.map((q, i) => `${i + 1}. ${q}`).join("\n\n");
    text = text.replace(/_/g, "_____");
    navigator.clipboard.writeText(text);
    toast.success(t("vocab.copied"));
  };

  useEffect(() => {
    if (attemptId) {
      getAttempt(attemptId);
    } else if (redoId) {
      getAttempt(redoId);
    } else {
      initQuestion();
    }
  }, []); // eslint-disable-line

  return (
    <div>
      <VideoModal
        isOpen={open}
        onClose={() => setOpen(false)}
        videoId="J71v7-u9dAI"
      />
      <PageTitle title={t("header.vocab", { ns: "common" })}>
        <Button
          onClick={() => setOpen(true)}
          className="text-2xl md:text-4xl pt-0"
        >
          <PlayCircle sx={{ fontSize: 36 }} />{" "}
          {t("common:header.tutorial-clip")}
        </Button>
      </PageTitle>
      <div className="bg-white shadow-md p-8">
        <div className="flex justify-between font-semibold text-primary mb-4">
          <div>{t("vocab.intro")}</div>
          <Button onClick={copyToClipboard}>
            <CopyAllOutlined />
          </Button>
        </div>
        <div className="flex flex-wrap md:grid md:grid-cols-5 mb-8">
          {vocabs.map((item, index) => (
            <div
              key={index}
              className="min-w-28 border border-solid border-collapse border-gray-300 p-2"
            >
              {item}
            </div>
          ))}
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ol className="flex flex-col gap-2 pl-6">
            {fields.map((item, index) => (
              <li key={item.id}>
                <AnswerBox
                  item={item}
                  index={index}
                  name="qa"
                  showResult={showResult}
                  methods={methods}
                />
              </li>
            ))}
          </ol>
          <div className="flex flex-row justify-end mt-10 gap-4">
            <GenerateNewButton
              label={t("comp.generate-new")}
              generateNewFunc={generateNewExercise}
              isLoading={isLoading}
              t={t}
            />

            {showResult ? (
              <Button
                type="button"
                variant="contained"
                className="flex gap-6"
                onClick={() => {
                  navigate(-1);
                }}
              >
                {t("vocab.done")}
              </Button>
            ) : (
              <Button type="submit" variant="contained" disabled={isLoading}>
                <MonetizationOnOutlined />
                &nbsp;{t("vocab.submit")}
                {isLoading && (
                  <CircularProgress className="absolute" size={20} />
                )}
              </Button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default VocabExercise;
